export default [
    {
        path: '/priceJiaXing',
        component: () => import('../pages/priceJiaXing'),
        meta: {
            title: "嘉兴计价方案"
        }
    },
    {
        path: '/priceHangzhou',
        component: () => import('../pages/priceHangzhou'),
        meta: {
            title: "杭州计价方案"
        }
    },
    {
        path: '/itinerary',
        component: () => import('../pages/itinerary')
    },
    {
        path: '/download',
        component: () => import('../pages/download')
    },
    {
        path: '/home',
        component: () => import('../pages/home')
    },
    {
        path: '/privacyPolicy',
        component: () => import('../pages/privacyPolicy'),
        meta: {
            title: "900游隐私政策"
        }
    },
    {
        path: '/servicePact',
        component: () => import('../pages/servicePact')
    },
    {
        path: '/invoice/:id',
        component: () => import('../pages/invoice'),
        meta: {
            title:'发票信息'
        }
    },
    {
        path: '*',
        component: () => import('../pages/download')
    }
]
